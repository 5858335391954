import React from "react";
import { nanoid } from "nanoid";
import SettingsButton from "./SettingsButton";


function Settings (props) {
  return(
    <form>
    {props.settings.map(fieldset =>
      <fieldset key={nanoid()}>
      <legend key={nanoid()}>{fieldset.category}</legend>
        {fieldset.settings.map(block =>
          <div key={nanoid()}>
            <div className="settingsDescriptons" key={nanoid()}>
              {block.description}
            </div>
            <div className="settingsButtons" key={nanoid()}>
              <SettingsButton
                button={block}
                passChange={props.changeSettings}
              />
            </div>
          </div>
        )}
      </fieldset>)}
    </form>
  )
}

export default Settings;