import React, { useEffect } from "react";

function ScoreChart(props) {

  function handleTryAgainClick () {
    props.setPage(1);
    props.setScore(100);
    props.setData({needsRefresh: "true"});
    props.setReload("true")
  }

  useEffect(() => {
    if (props.score >= props.highScore){
      props.setHighScore(props.score);      
    }
  },[]);

  return (
    <div className="score-chart">
      <div id="score-board">
        <div className="score-names">
          <div id="high-score">High Score</div>
          <div id="score">Score</div>
        </div>
        <div className="score-numbers">
          <div id="high-score-num">{props.highScore}</div>
          <div id="score-num">{props.score}</div>
        </div>
      </div>
      <div id="score-buttons">
        <button onClick={() => handleTryAgainClick()}>
          Try Again
        </button>
        <button>
          Load New
        </button>
      </div>
    </div>
  );
}

export default ScoreChart;