import React, { useEffect } from "react";

//formula for score = corrrect words * 1 / time elapsed - number of incorrect key * 100

let date = new Date;

function Hud(props) {

  const hudSet = props.settings[1].settings[1]
  const speedType = props.settings[0].settings[0];

  useEffect (() => {
    let timeElapsed = new Date - date;
    let multiplier;
    date = new Date;

    if (speedType.value === "On") {
      multiplier = 100;
    } else if (speedType.value === "Off") {
      multiplier = 10;
    } else {
      console.error("Not a valid value for setting id=00")
    }
    
    let newScore = Math.round(props.score + multiplier * (props.correct * 1/(timeElapsed)));
    props.setScore(newScore);
  },[props.correct]);

  useEffect (() => {
    let newScore = Math.round(props.score - 100)
    props.setScore(newScore);
  },[props.mistakes])

  useEffect (() => {
    if (props.score < props.highScore) {
      return;
    } else if (props.score >= props.highScore) {
      props.setHighScore(props.score);
    }
  },[props.score])


  let hudLoc = "";

  switch (hudSet.value) {
    case "⌞":
      hudLoc = "hudBL";
    break;

    case "⌟":
      hudLoc = "hudBR";
    break;

    case "⌜":
      hudLoc = "hudTL";
    break;

    case "⌝":
      hudLoc = "hudTR";
    break;

    default:
      console.error("Not a valid value for setting id=11");
  }
  
  return(
    <div className={hudLoc} id="hud">
      Score: {props.score} <br/>
      High Score: {props.highScore}
    </div>
  );
}

export default Hud;