import React, { useState, useEffect, useRef, useCallback } from "react";

function Cursor (props) {

  const cursorValue = props.settings[1].settings[0].value;
  const cursorRef = useRef();
  const [cursor, setCursor] = useState(<div ref={cursorRef}></div>);

  useEffect (() => {

      if (cursorValue === "Show") {
    setCursor(
      <div id ="container" key="containter" ref={cursorRef}>
        <div id="cursor" key="cursor" ></div>
      </div>
    );
    } else if (cursorValue === "Hide") {
      setCursor(
        <div ref={cursorRef}></div>
      );
    } else {
      console.error("Not a valid value for setting id=10");
    }
    cursorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  },[cursorValue]);

  return (
    cursor
  );
}

export default Cursor;
