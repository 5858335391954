import React, { useEffect, useState } from "react"
import "./App.css"
import Hud from "./components/Hud"
import MemorizePage from "./components/MemorizePage.js"
import Settings from "./components/Settings.js"
import ScoreChart from "./components/ScoreChart"
import Cogno from "./images/Cogno"
import Gear from "./images/Gear"

let numOfMistakes = 0;
let numOfCorrect = 0;

function App(props) {


  const [settings, setSettings] = useState(props.settings);
  const [page, setPage] = useState(1);
  const [overlay, setOverlay] = useState(1);
  const [mistakes, setMistakes] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [score, setScore] = useState(100);
  const [reload, setReload] = useState("false");
  const [highScore, setHighScore] = useState(0);

  useEffect(() => {
    console.log(highScore);
  },[highScore])

  useEffect(() => {
    if (page === 2 && props.memData.needsRefresh === "false") {
      setScore(score + 100);
      setOverlay(1);
    } else if (props.memData.needsRefresh === "true") {
      setScore(0);
      numOfCorrect = 0;
      setCorrect(0);
      numOfMistakes = 0;
      setMistakes(0);
      props.setData({currentPara: 0});
      props.setData({needsRefresh: "false"})
    }
    if (page === 3) {
      setOverlay(1)
    }
  },[page]);

  function countMistakes () {
    numOfMistakes++;
    setMistakes(numOfMistakes);
  }

  function countCorrect () {
    numOfCorrect++;
    setCorrect(numOfCorrect);
  }


  function memConfirmRefresh () {
    props.setData({needsRefresh: "false"});
  }

  function changeSettings(id, value, refresh) {
      let settingIndex = id.split("");
      let changedSetting = settings[settingIndex[0]].settings[settingIndex[1]];
      let newSettings = settings;
      changedSetting.value = value;
      changedSetting.refresh = refresh;
      if (refresh === "true" && props.memData.needsRefresh !== true) {
        props.setData({needsRefresh: "true"});
        setReload("true");
      }
      newSettings[settingIndex[0]].settings[settingIndex[1]] = changedSetting;
      setSettings(newSettings);
  }

  function returnMemPage () {
    if (overlay === 1) {
      return (
        <div className="overlay-container" onClick={() => setOverlay(0)}>
            <div className="overlay-text">
              Click to continue
            </div>
            <div className="overlay-background">
              <MemorizePage
                settings={settings}
                confirm={memConfirmRefresh}
                data={props.memData}
                setData={props.setData}
                setShownPara={props.setShownPara}
                setPage={setPage}
                countMistakes={countMistakes}
                countCorrect={countCorrect}
                reload={reload}
              />
            </div>
          </div>
      );
    } else if (overlay === 0) {
      return (
        <MemorizePage
          settings={settings}
          confirm={memConfirmRefresh}
          data={props.memData}
          setData={props.setData}
          setShownPara={props.setShownPara}
          setPage={setPage}
          countMistakes={countMistakes}
          countCorrect={countCorrect}
          reload={reload}
        />
      );
    } else {
      console.error(`invalad overlay number: ${overlay}`)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
      <ul>
        <li>
          <div className="logoImg">
            <Cogno />
          </div>
        </li>
        <li className="logo">
          
          { <button onClick={() => setPage(1)}>
            Cogno
          </button>}
        </li>
        <li className="cogno">
          <button onClick={() => setPage(1)}>
            <Cogno />
          </button>
        </li>
        <li className="settings">
          { <button onClick={() => setPage(2)}>
           <Gear />
          </button>}
        </li>
      </ul>
      </header>
      <div className="Content">
        {page === 1 &&
          returnMemPage()
        }
        {page === 1 &&
          <Hud
            settings={settings}
            mistakes={mistakes}
            correct={correct}
            score={score}
            setScore={setScore}
            highScore={highScore}
            setHighScore={setHighScore}
          />
        }
        {page === 2 &&
          <Settings
            changeSettings={changeSettings}
            settings={settings}
          />
        }
        {page === 3 &&
          <div className="overlay-container">
            <ScoreChart
              score={score}
              highScore={highScore}
              setPage={setPage}
              setScore={setScore}
              setHighScore={setHighScore}
              setData={props.setData}
              setReload={setReload}
            />
            <div className="overlay-background">
              <MemorizePage
                settings={settings}
                confirm={memConfirmRefresh}
                data={props.memData}
                setData={props.setData}
                setShownPara={props.setShownPara}
                setPage={setPage}
                countMistakes={countMistakes}
                countCorrect={countCorrect}
              />
            </div>
          </div>  
        }
      </div>
      <div className="Sidebar">
        <ul>
          <li></li>
          <li></li>
        </ul>
      </div>
      <footer>
        <ul>
          <li>You can find me on
            <a href="https://github.com/KonFlinders"> Github </a>
            and
            <a href="https://flinders.tech"> my website</a>.
          </li>
        </ul>
      </footer>
    </div>
  );
}

export default App;
