import React, { useState, useEffect, useCallback } from "react";
import { nanoid } from "nanoid";
import Memorize from "./Memorize";

let paraDisplay = [];
let numOfHidden;

function setPara (para, name) {
  let paraArray = para.split(" ");
  return paraArray.map(string =>
    <li className={name}
    key={nanoid()}
    >
      {string}
    </li>
  );
}


function MemorizeList(props) {
  const numOfHiddenPara = props.settings[0].settings[1];
  const showNextPara = props.settings[0].settings[2];

  const [display, setDisplay] = useState(memDisplayGen);
  
  switch (numOfHiddenPara.value) {
    case "One":
      numOfHidden = 0;
    break;

    case "Two":
      numOfHidden = 1;
    break;

    case "Three":
      numOfHidden = 2;
    break;

    case "All":
      numOfHidden = Infinity;
    break;

    default:
      console.error("Not a valid value for setting id=01");
  }


  if (showNextPara.value === "Off" &&
    props.data.currentPara !== 0 &&
    props.data.shownArray.length === 0
    ) {
      let newDisplayArray = props.data.displayArray.map(e =>
        <li key={e.key} className="hidden">{e.props.children}</li>);
      props.setData({displayArray: newDisplayArray});
  }

  function memRefreshPage() {
    setDisplay(memDisplayGen());
  }

  function memDisplayGen () {
    paraDisplay = [];
      for (let i = 0; i < props.data.text.length; i++) {
        if (i === props.data.currentPara) {
          paraDisplay.push(
          <Memorize
            key={nanoid()}
            settings={props.settings}
            confirm={props.confirm}
            data={props.data}
            setData={props.setData}
            setPage={props.setPage}
            memRefreshPage={memRefreshPage}
            countMistakes={props.countMistakes}
            countCorrect={props.countCorrect}
            reload={props.reload}
          />)
        } else if ( i > numOfHidden + props.data.currentPara || i < props.data.currentPara) {
          paraDisplay.push(<ul key={nanoid()}>{setPara(props.data.text[i], "shown")}</ul>);
        } else {
          paraDisplay.push(<ul key={nanoid()}>{setPara(props.data.text[i], "hidden")}</ul>);
        }
      }
      return paraDisplay;
  }


  return (
    <div className="To-memorize">
        {display}
    </div>
  );
}

export default MemorizeList;