import React, { useState, useEffect, useCallback, } from "react";
import { nanoid } from "nanoid";
import Cursor from "./Cursor"

let curWordShown = [];
let curWordHidden = [""];
let curWordIndex = 0;
let hints = 0;


function setHidden (string) {
  return <li className="hidden" key={nanoid()}>{string}</li>; 
}

function setShown (string) {
  return <li className="shown" key={nanoid()}>{string}</li>;
}

function reset() {
  curWordShown = [];
  curWordHidden = [""];
  curWordIndex = 0;
}



function Memorize(props) {
  const caseSensitive = props.settings[0].settings[3];
  const speedType = props.settings[0].settings[0];
  const numberOfHints = props.settings[0].settings[4];


  let hiddenArray = props.data.hiddenArray;
  let shownArray = props.data.shownArray;
  let displayArray = props.data.displayArray;

  useEffect (() => {
    switch (numberOfHints.value) {
      case "One":
        hints = 2;
      break;

      case "Three":
        hints = 4;
      break;

      case "Five":
        hints = 6;
      break;

      case "Unlimited":
        hints = Infinity;
      break;

      default:
        console.error("Not a valid value for setting id=04");
    }
  },[numberOfHints])

  const [display, updateDisplay] = useState(
    <ul>
      {
        shownArray.length === 0 &&
        curWordShown.length === 0 &&
        <Cursor settings={props.settings}/>
      }

      {displayArray}
    </ul>);
  


  useEffect(() => {
      reset();
  },[props.reload]);



  //The below event handler and useEffect code solves a memory leak when a key is pressed while the settings page is loaded. Thanks Devtrium! https://devtrium.com/posts/how-keyboard-shortcut
  
  const handleKeyPress = useCallback((e) => {
    modArray(e);
    e.preventDefault();
    if (
    hiddenArray.length === 0 &&
    curWordHidden[0] === ""
    ) {
      if (props.data.currentPara < props.data.text.length -1) {
        props.setData({currentPara: props.data.currentPara + 1});
        props.memRefreshPage();
        curWordIndex = 0;  
      } else {
        props.setPage(3);
      }      
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  },[handleKeyPress]);

  function countHints() {

    if (hints > 1) {
      hints = hints - 1;
      console.log (hints);
      return true;
    } else {
      return false;
    }
  }

  function checkKey(key, char) {
    if (char === undefined) {
      return;
    }

    if (key === "F3" && countHints()) {
      return true;
    }

    if (caseSensitive.value === "On") {
       if (key === char) {
         return true;
       } else {
         return false;
       }
    } else if (caseSensitive.value === "Off") {
      if (key === char.toLowerCase() || key === char.toUpperCase()) {
        return true;
      } else {
        return false;
      }
    } else {
      console.error("Not a valid value for setting id=03");
    }
  }

  function modArray(e) {

    if (e.key === "Escape") {
      return;
    }

    if ( speedType.value === "On" ) {
      if ( !(hiddenArray.length === 0) && checkKey(e.key, hiddenArray[0][0])) {
        shownArray.push(hiddenArray[0]);
        hiddenArray.shift();
        refreshDisplay();
        props.setData({
          hiddenArray: hiddenArray,
          shownArray: shownArray,
          displayArray: displayArray,});
        props.countCorrect();
      } else {
        props.countMistakes();

      }
    } else if ( speedType.value === "Off" ) {
      if ( !(hiddenArray.length === 0) && checkKey(e.key, hiddenArray[0][0]) && curWordHidden[0] === "") {
        curWordHidden = hiddenArray[0].split("");
        curWordShown.push(curWordHidden[0]);
        curWordHidden.shift();
        hiddenArray.shift();
        refreshDisplay();
        props.setData({
          hiddenArray: hiddenArray,
          shownArray: shownArray,
          displayArray: displayArray,});
        props.countCorrect();

      } else if (checkKey(e.key, curWordHidden[0])) {
        curWordShown.push(curWordHidden[0]);
        curWordHidden.shift();
        refreshDisplay();
        props.countCorrect();
        
      } else if (e.key === " " && curWordHidden.length === 0) {
        shownArray[curWordIndex] = curWordShown.join("");
        curWordHidden = [""];
        curWordShown = [];
        curWordIndex++;
        refreshDisplay();
        props.countCorrect();
      } else {
        props.countMistakes();
      }
    } else {
      props.countMistakes();
    }
  }

  function refreshDisplay() {
    if ( speedType.value === "On" || curWordHidden[0] === "" ) {
      displayArray = shownArray.map(setShown).concat(
        <div className="wordContainer" key={nanoid()}>
          <Cursor
            settings={props.settings}
          />
        </div>,
        hiddenArray.map(setHidden));
    updateDisplay(<ul>{displayArray}</ul>);
    } else {
    displayArray = shownArray.map(setShown).concat(
      <div className="wordContainer" key={nanoid()}>
        <div className="shownWord" key={nanoid()}>
          {curWordShown.join("")}
        </div>
          <Cursor
            settings={props.settings}
          />
        <div className="hiddenWord" key={nanoid()}>
          {curWordHidden.join("")}
        </div>
      </div>,
      hiddenArray.map(setHidden));
    updateDisplay(<ul>{displayArray}</ul>);
    props.setData({displayArray: displayArray});
    }
  }

  return (
    <div className="Memorizing">
      {display}
    </div>
  );
}

export default Memorize;