import React, { useState } from "react";
import { nanoid } from "nanoid";



function SettingsButton(props) {
  const [button, setButton] = useState(props.button.value);

  function handleChange (e) {
    setButton(e.target.value)
    props.passChange(e.target.id, e.target.value, e.target.attributes.refresh.value)
  }
  
  return(
    props.button.options.map(options =>
      <div className="buttonContainer" key={nanoid()}>
        <input
          type={props.button.type}
          name={props.button.name}
          id={`${props.button.id}${options}`}
          value={options}
          refresh={props.button.refresh}
          checked={button === options}
          onChange={handleChange}
        >
        </input>
        <label
          key={nanoid()}
          htmlFor={`${props.button.id}${options}`}>
          {options}
        </label>
      </div>)
  )
}

export default SettingsButton;