import React from 'react';
import { nanoid } from 'nanoid';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const SETTINGS = [
  { category: "Behavior",
    settings: [
      { type: "radio",
      name: "speedType",
      id: "00",
      description: "Press only the first letter to reveal each word.",
      options: ["On", "Off"],
      value: "On",
      refresh: "true" },
      
      { type: "radio",
      name: "numOfHiddenPara",
      id: "01",
      description: "Choose how many paragraphs are hidden.",
      options: ["One", "Two", "Three", "All"],
      value: "Two",
      refresh: "false" },

      { type: "radio",
      name: "showNextPara",
      id: "02",
      description: "Briefly show the next paragraph after a paragraph is finished.",
      options: ["On", "Off"],
      value: "Off",
      refresh: "false" },

      { type: "radio",
      name: "caseSensitive",
      id: "03",
      description: "Toggle case sensitivity.",
      options: ["On", "Off"],
      value: "Off",
      refresh: "false" },

      { type: "radio",
      name: "numberOfHints",
      id: "04",
      description: "Number of hints allowed.",
      options: ["One", "Three", "Five", "Unlimited"],
      value: "Three",
      refresh: "false" },

    ],
  },
  { category: "Appearance",
    settings:[
      { type: "radio",
      name: "toggleCursor",
      id: "10",
      description: "Show or hide cursor.",
      options: ["Show", "Hide"],
      value: "Show",
      refresh: "false" },

      { type: "radio",
      name: "hudPos",
      id: "11",
      description: "Control which corner the scoreboard is in.",
      options: ["⌜", "⌝", "⌞", "⌟"],
      value: "⌟",
      refresh: "false" },
      
    ],
  },
]

const DATA = [
  "34 And at the end of the days I Nebuchadnezzar lifted up mine eyes unto heaven, and mine understanding returned unto me, and I blessed the most High, and I praised and honoured him that liveth for ever, whose dominion is an everlasting dominion, and his kingdom is from generation to generation:",

  "35 And all the inhabitants of the earth are reputed as nothing: and he doeth according to his will in the army of heaven, and among the inhabitants of the earth: and none can stay his hand, or say unto him, What doest thou?",

  "36 At the same time my reason returned unto me; and for the glory of my kingdom, mine honour and brightness returned unto me; and my counsellors and my lords sought unto me; and I was established in my kingdom, and excellent majesty was added unto me.",

  "37 Now I Nebuchadnezzar praise and extol and honour the King of heaven, all whose works are truth, and his ways judgment: and those that walk in pride he is able to abase."
]

let memData = {
  text: DATA,
  currentPara: 0,
  hiddenArray: [],
  shownArray: [],
  displayArray: [],
  needsRefresh: "false",
}

function setShownPara (para) {
  let paraArray = para.split(" ");
  return paraArray.map(string =>
    <li className="shown"
    key={nanoid()}
    >
      {string}
    </li>
  );
}

function memSetup () {
  memData.shownArray = [];
  memData.hiddenArray = memData.text[memData.currentPara].split(" ");
  memData.displayArray = setShownPara(memData.text[memData.currentPara]);
}

memSetup();

function memHandleChange (...rest) {
  if (rest[0].hiddenArray !== undefined) {
    memData.hiddenArray = rest[0].hiddenArray;
  }
  
  if (rest[0].shownArray !== undefined) {
    memData.shownArray = rest[0].shownArray;
  }

  if (rest[0].displayArray !== undefined) {
    memData.displayArray = rest[0].displayArray;
  }
  
  if (rest[0].currentPara !== undefined) {
    memData.currentPara = rest[0].currentPara;
    memSetup();
  }

  if (rest[0].needsRefresh !== undefined) {
    memData.needsRefresh = rest[0].needsRefresh;
  }
}


ReactDOM.render(
  <React.StrictMode>
    <App
      settings={SETTINGS}
      memData={memData}
      setData={memHandleChange}
    />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
